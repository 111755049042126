<template>
  <div>
    <!-- select 2 demo -->
    <vue-easy-lightbox escDisabled moveDisabled :visible="visible" :imgs="detailMedia" :index="detailMedia.length"
      @hide="show()"></vue-easy-lightbox>

    <b-modal id="modal-login" centered ok-only no-close-on-backdrop hide-footer="true" title="Assign Talents">
      <b-form>
        <b-form-group label="Select Talents" invalid-feedback="Talents are required." ref="talents">
          <v-select multiple v-model="myObj.talents" :disabled="dataLoading" :options="allTalents" label="email"
            :reduce="(val) => val.id" input-id="Id" placeholder="Select talents" required @input="CheckTalent()"
            ref="talents">
            <template slot="option" slot-scope="option" placeholder="Select talents">
              <img class="myImg" :src="option.pic" />
              <span style="margin: 0px 10px;">{{ option.name }}</span>
            </template></v-select>
        </b-form-group>
      </b-form>
      <!-- <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>

      <br /> -->
      <div class="mt-1">
        <b-button class="float-right ml-5" variant="primary" :disabled="request" @click="AddFlavour()">
          <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modal-detail" centered no-close-on-backdrop hide-footer="true" title="Recruitment Request Deatils">
      <b-tabs>
        <b-tab title="Scout Details" active>
          <b-row>
            <b-col md="4">
              <h4>{{ detailObj.scoutName }}</h4>
              <b-img :src="detailObj.scoutPic" style="height: 130px; width: 130px; object-fit: cover" rounded
                alt="Scout Pic" />
            </b-col>
            <b-col md="8">
              <h4>{{ requestData.title }}</h4>
              <p>{{ requestData.description }}</p>
              <div class="mb-1">
                <feather-icon size="16" icon="MapPinIcon" />
                <span v-if="requestData.location == null || requestData.location == ''
                  " style="margin-left: 7px">--</span>
                <span v-else style="margin-left: 7px">{{
                  requestData.location
                }}</span>
              </div>
              <div class="mb-1">
                <feather-icon size="16" icon="CalendarIcon" />
                <span style="margin-left: 7px"></span>{{ requestData.date }}
              </div>
              <b-badge variant="info">
                {{ requestData.status }}
              </b-badge>
            </b-col>
          </b-row>
          <hr />
          <b-row class="mb-50" v-if="prefferList.length != 0">
            <b-col md="12">
              <h4>Preferred Talents</h4>
            </b-col>
            <b-col>
              <span v-for="tal in prefferList" :key="tal" variant="info" class="mr-50">
                {{ tal }}
              </span>
            </b-col>
          </b-row>

          <b-row v-if="detailMedia.length != 0">
            <b-col md="12">
              <h4>Medias</h4>
            </b-col>
            <b-col>
              <div v-for="img in detailMedia" :key="img">
                <b-img :src="img" style="height: 130px; width: 130px; object-fit: cover" rounded alt="media"
                  @click="show()" />
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Talents Assigned">
          <div v-if="requestTalents.length > 0">
            <b-row v-for="talent in requestTalents" :key="talent.userID" class="pb-1">
              <b-col md="4">
                <b-img :src="talent.pic" style="height: 130px; width: 130px; object-fit: cover" rounded alt="Scout Pic" />
              </b-col>
              <b-col md="8"
                style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start;">
                <h4>{{ talent.name }}</h4>
                <p>{{ talent.bio }}</p>
                <div class="mb-1">
                  <feather-icon size="16" icon="MailIcon" />

                  <span style="margin-left: 7px">{{ talent.email }}</span>
                </div>
                <div class="mb-1">
                  <feather-icon size="16" icon="FlagIcon" />

                  <span style="margin-left: 7px">
                    {{ talent.nationality }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row v-else class="p-1">
            <b-col>
              <h4 class="text-center">No Talents assigned</h4>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Appointed Talents">
          <div v-if="allAppointments.length > 0">
            <b-row class="pb-1" v-for="appoint in allAppointments" :key="appoint">
              <b-col md="4">
                <b-img :src="appoint.pic" style="height: 130px; width: 130px; object-fit: cover" rounded alt="Pic" />
              </b-col>
              <b-col md="8"
                style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start;">
                <h4>{{ appoint.name }}</h4>
                <!-- <p>{{ appoint.bio }}</p> -->
                <div class="mb-1">
                  <feather-icon size="16" icon="MailIcon" />

                  <span style="margin-left: 7px">{{ appoint.email }}</span>
                </div>
                <div class="mb-1">
                  <feather-icon size="16" icon="FlagIcon" />

                  <span style="margin-left: 7px">
                    {{ appoint.nationality }}</span>
                </div>
                <div style="display: flex" v-if="appoint.appointment.contract !== null">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon mr-1"
                    v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="View Contract"
                    :href="appoint.appointment.contract" target="_blank">
                    <feather-icon size="14" icon="FileIcon" />
                  </b-button>
                  <template v-if="appoint.appointment.contractStatus == 'pending' ||
                    appoint.appointment.contractStatus == null
                    ">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="btn-icon mr-1"
                      v-b-tooltip.hover.v-success v-b-tooltip.placement.left title="Approve Contract"
                      @click="approveContract(appoint.appointment)">
                      <feather-icon size="16" icon="CheckIcon" />
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="btn-icon mr-1"
                      v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Reject Contract"
                      @click="openReason(appoint.appointment)">
                      <feather-icon size="16" icon="XIcon" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button v-if="appoint.appointment.contractStatus == 'rejected'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" class="btn-icon mr-1"
                      v-b-tooltip.hover.v-success v-b-tooltip.placement.left title="Approve Contract"
                      @click="approveContract(appoint.appointment)">
                      <feather-icon size="16" icon="CheckIcon" />
                    </b-button>
                    <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger"
                      class="btn-icon mr-1" v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Reject Contract"
                      @click="openReason(appoint.appointment)">
                      <feather-icon size="16" icon="XIcon" />
                    </b-button>
                  </template>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row v-else class="p-1">
            <b-col>
              <h4 class="text-center">No Appointments</h4>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal id="modal-reason" centered no-close-on-backdrop hide-footer="true" title="Reason">
      <b-row>
        <b-col md="12">
          <b-form-group label="Reason for rejection" invalid-feedback="Reason is required." ref="talents">
            <b-form-input id="name-input" ref="reason" placeholder="Enter reason here." required @focusout="checkReason()"
              v-model="contractObj.contractReason" />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-button class="float-right ml-5" variant="primary" @click="rejectContract()">
            <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
            <span v-if="!request" class="text-nowrap">Reject</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <b-row align-h="end" style="align-items: center ;">
          <b-col class="" cols="*" lg="*" md="*" sm="*">
            <b-button variant="warning" @click="LoadData()" style="margin-left: 10px;">
              <span class="text-nowrap">
                All
                <b-badge variant="danger" class="badge-glow" v-if="allCount != 0">
                  {{ allCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="secondary" @click="LoadFiltered('pending')" style="margin-left: 10px;">
              <span class="text-nowrap">Pending
                <b-badge variant="danger" class="badge-glow" v-if="pendingCount != 0">
                  {{ pendingCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="info" @click="LoadFiltered('in progress')" style="margin-left: 10px;">
              <span class="text-nowrap">Inprogress
                <b-badge variant="danger" class="badge-glow" v-if="inprogressCount != 0">
                  {{ inprogressCount }}
                </b-badge></span>
            </b-button>
            <b-button variant="success" @click="LoadFiltered('completed')" style="margin-left: 10px;">
              <span class="text-nowrap">Completed
                <b-badge variant="danger" class="badge-glow" v-if="completeCount != 0">
                  {{ completeCount }}
                </b-badge></span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(scoutPic)="data">
            <b-img :src="data.value" style="width: 100px; height: 100px; object-fit: cover" rounded alt="Rounded image" />
          </template>

          <template #cell(actions)="data">
            <template v-if="data.item.status.toLowerCase() == 'pending' ||
              data.item.status.toLowerCase() == 'in progress'
              ">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon ml-1"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Assign Talents"
                @click="OpenAddModal(data.item)">
                <feather-icon size="16" icon="UserIcon" />
              </b-button>
            </template>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon ml-1"
              v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Details" @click="openDetails(data.item)">
              <feather-icon size="16" icon="EyeIcon" />
            </b-button>

            <!-- <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item    @click="OpenEditModal(data.item)">
              <feather-icon icon="EditIcon" />
              
              <span class="align-middle ml-50"   >Edit</span>
            </b-dropdown-item>
              <b-dropdown-item    @click="DeleteFlavour(data.item.categoryId)">
              <feather-icon icon="XIcon" />
              
              <span class="align-middle ml-50"   >Delete</span>
            </b-dropdown-item> -->

            <!-- <b-dropdown-item @click="AcceptOrder(data.item.orderId)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50" >Accept order</span>
            </b-dropdown-item>

            <b-dropdown-item @click="DenyOrder(data.item.orderId)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Decline order</span>
            </b-dropdown-item> 
          </b-dropdown>-->
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              ">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              ">
              <b-pagination v-model="currentPage" :total-rows="totalOrders" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormFile,
  BTab,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";
import { forEach } from "postcss-rtl/lib/affected-props";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    //Multiselect,
    VueEasyLightbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    //BMedia,
    //BMediaAside,
    BSpinner,
    BTab,
    BTabs,
    BImg,
    //    BMedia,
    BFormGroup,
    //BAvatar,
    BBadge,
    //BLink,
    //BFormFile,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
    this.LoadTalents();
  },
  data() {
    return {
      cities: [],
      selectedcities: [],
      myfilesss: "",
      plus1: "",
      fileProfile1: "",
      logoloading1: false,
      regions: [
        {
          id: 0,
          name: "",
          image: "",
          zoneId: 0,
        },
      ],
      nextTodoId: 2,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus:
        "https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png",
      fileProfile: "",
      logoloading: false,
      errors: {
        status: false,
        regions: false,
      },
      statusOptions: ["Active", "Inactive"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        "#",
        { label: "Pic", key: "scoutPic" },
        { label: "name", key: "scoutName" },
        { label: "title", key: "title" },
        { label: "location", key: "location" },
        // { label: "email", key: "email" },
        "actions",
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      myObj: {
        requestID: 0,
        scoutID: 0,
        talents: [],
      },
      request: false,
      allTalents: [],
      detailObj: {},
      requestData: {},
      requestTalents: [],
      allAppointments: {},
      detailMedia: [],
      prefferList: [],
      visible: false,
      allCount: 0,
      pendingCount: 0,
      completeCount: 0,
      inprogressCount: 0,
      dataLoading: false,
      assigned: null,
      contractObj: {},
    };
  },
  methods: {
    CheckVAT() {
      var elem = this.$refs["vat"];
      const regex = /^\d+(,\d{1,2})?$/;
      if (parseInt(this.myObj.country.vat) !== 0) {
        if (
          regex.test(this.myObj.country.vat) &&
          this.myObj.country.vat <= 100
        ) {
          this.myObj.country.vat = parseInt(this.myObj.country.vat);
          return (elem.state = true);
        } else {
          return (elem.state = false);
        }
      } else {
        this.myObj.price = "";
        return (elem.state = false);
      }
    },
    CheckTalent() {
      console.log(this.myObj.talents);
      var elem = this.$refs["talents"];
      if (this.myObj.talents.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkReason() {
      console.log(this.contractObj.contractReason);
      var elem = this.$refs["reason"];
      if (
        this.contractObj.contractReason === "" ||
        this.contractObj.contractReason == null
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRegions() {
      if (this.regions.length == 0) {
        return (this.errors.regions = true);
      } else {
        this.regions.forEach((elem) => {
          if (
            elem.image == 0 ||
            elem.image == "" ||
            elem.image == undefined ||
            elem.name == 0 ||
            elem.name == "" ||
            elem.name == undefined
          ) {
            return (this.errors.regions = true);
          } else {
            return (this.errors.regions = false);
          }
        });
      }
    },
    repeateAgain() {
      for (let index = 0; index < this.regions.length; index++) {
        if (
          this.regions[index].image == 0 ||
          this.regions[index].image == "" ||
          this.regions[index].image == undefined ||
          this.regions[index].name == 0 ||
          this.regions[index].name == "" ||
          this.regions[index].name == undefined
        ) {
          return 0;
        }
      }
      this.regions.push({
        // id: this.nextTodoId += this.nextTodoId,
        id: 0,
        zoneId: 0,
        name: this.regions.name,
        image: this.regions.image,
      });
      console.log(this.regions);
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.regions.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    CheckName() {
      var elem = this.$refs["country"];
      if (this.myObj.country.name == "" || this.myObj.country.name < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      var elem = this.$refs["image"];
      if (this.myObj.image == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCategory() {
      console.log(this.myObj.categoryId);
      var elem = this.$refs["categoryId"];
      if (
        this.myObj.categoryId == 0 ||
        this.myObj.categoryId == undefined ||
        this.myObj.categoryId == null
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    OpenAddModal(item) {
      //

      console.log(item);
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          "https://api.geotalent.co/api/Requests/LoadAssignedTalents?requestID=" +
          item.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data);
          if (response.data.status == "success") {
            this.myObj = {
              requestID: item.id,
              scoutID: item.scoutID,
              talents: response.data.data,
            };
            this.$bvModal.show("modal-login");
            console.log(this.myObj);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.country.id = row.id;
      this.myObj.country.name = row.name;
      this.myObj.country.vat = row.vat;
      this.myObj.cityIds = row.cities;
      console.log(this.myObj);
    },
    openDetails(item) {
      console.log(item);
      this.detailObj = item;
      // this.detailObj.date = item.date.split("T")[0];
      // if (item.media === null || item.media === "") {
      //   this.detailMedia = [];
      // } else {
      //   // console.log("media", item.media);
      //   this.detailMedia = item.media.split(",");
      // }
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          "https://api.geotalent.co/api/Requests/LoadSelectedRequest?requestID=" +
          item.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          this.requestData = response.data.requestData;
          this.requestData.date = this.requestData.date.split("T")[0];
          if (
            this.requestData.media === null ||
            this.requestData.media === ""
          ) {
            this.detailMedia = [];
          } else {
            // console.log("media", requestData.media);
            this.detailMedia = this.requestData.media.split(",");
          }
          if (this.requestData.preferredTalents) {
            this.prefferList = this.requestData.preferredTalents.split(",");
          } else this.prefferList = [];
          console.log(this.prefferList);

          this.requestTalents = response.data.talents;
          this.allAppointments = response.data.appointment;
          this.$bvModal.show("modal-detail");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    show() {
      this.visible = !this.visible;
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://localhost:44355/api/Countries/DeleteCountry/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Country has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      var axios = require("axios");
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        console.log(this.fileProfile);
        axios
          .post("https://geoupload.appick.io/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus = fn1;
            //
            this.myObj.image = this.plus;
            console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = "loaded";

            console.log(this.loading);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    selectlogo1(e, i) {
      var myfiles = e.target.files || e.dataTransfer.files;
      console.log(myfiles[0]);
      // if (!files.length)
      //   return;

      // this.myfilesss.splice(i, 0, URL.createObjectURL(files[0]));
      // console.log(this.myfilesss)
      console.log("Hellloo");
      // return
      //this.fileProfile1 = ;
      var axios = require("axios");
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (myfiles !== "") {
        this.logoloading1 = true;
        let formData = new FormData();
        formData.append("file", myfiles[0]);
        console.log(myfiles);
        axios
          .post("https://geoupload.appick.io/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus1 = fn1;
            this.regions[i].image = this.plus1;
            //
            //console.log(.this.regions);
            //console.log(this.myObj.coverImg);
            this.logoloading1 = "loaded";

            console.log(this.loading);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo1(index) {
      //console.log(0);
      this.regions[index].image = "";
      this.logoloading = false;
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = "";
      this.logoloading = false;
    },
    approveContract(item) {
      const obj = {
        id: item.id,
        contract: item.contract,
        contractStatus: "approved",
        contractReason: item.contractReason,
      };
      var axios = require("axios");
      var config = {
        method: "post",
        url: "https://api.geotalent.co/api/Appointments/SaveContract",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
        data: obj,
      };

      axios(config)
        .then((response) => {
          if (response.data.status === "success") {
            console.log(response.data);
            this.LoadData();
            this.$bvToast.toast("Contract approved successfully!.", {
              title: "Success!",
              variant: "success",
              toaster: "b-toaster-top-center",
            });
          } else {
            this.$bvToast.toast("Something went wrong!.", {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-bottom-center",
            });
            this.request = false;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    openReason(item) {
      this.contractObj = {
        id: item.id,
        contract: item.contract,
        contractStatus: item.contractStatus,
        contractReason: item.contractReason,
      };
      this.$bvModal.show("modal-reason");
    },
    rejectContract() {
      if (this.checkReason() == false) {
        return this.$bvToast.toast("Please select talents.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        this.contractObj.contractStatus = "rejected";
        var axios = require("axios");
        var config = {
          method: "post",
          url: "https://api.geotalent.co/api/Appointments/SaveContract",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.contractObj,
        };

        axios(config)
          .then((response) => {
            this.request = true;
            if (response.data.status === "success") {
              console.log(response.data);
              this.request = false;
              this.$bvModal.hide("modal-reason");
              this.$bvModal.hide("modal-detail");
              this.LoadData();
              this.$bvToast.toast("Contract rejected successfully!.", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
              });
            } else {
              this.$bvToast.toast("Something went wrong!.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-bottom-center",
              });
              this.request = false;
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    AddFlavour() {
      console.log(this.myObj);
      this.CheckTalent();
      if (this.CheckTalent() == false) {
        return this.$bvToast.toast("Please select talents.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        this.request = true;
        var axios = require("axios");
        var config = {
          method: "post",
          url: "https://api.geotalent.co/api/Requests/AssignTalents",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
          data: this.myObj,
        };

        axios(config)
          .then((response) => {
            if (response.data.status === "success") {
              console.log(response.data);
              this.request = false;
              this.$bvModal.hide("modal-login");
              this.LoadData();
              this.$bvToast.toast("Talent assigned successfully!.", {
                title: "Success!",
                variant: "success",
                toaster: "b-toaster-top-center",
              });
            } else {
              this.$bvToast.toast("Something went wrong!.", {
                title: "Error!",
                variant: "danger",
                toaster: "b-toaster-bottom-center",
              });
              this.request = false;
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    LoadFiltered(status) {
      console.log(status);
      var axios = require("axios");
      var config = {
        method: "get",
        url:
          "https://api.geotalent.co/api/Requests/LoadData?status=" + status,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data.data);
          this.items = response.data.data;
          if (status == "pending") {
            this.pendingCount = this.items.length;
          } else if (status == "in progress") {
            this.inprogressCount = this.items.length;
          } else {
            this.completeCount = this.items.length;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadData() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Requests/LoadData?status=",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data.data);
          this.items = response.data.data;
          this.allCount = this.items.length;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadTalents() {
      this.dataLoading = true;
      var axios = require("axios");
      var config2 = {
        method: "get",
        url: "https://api.geotalent.co/api/UserProfile/LoadTalents",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config2)
        .then((response) => {
          console.log(response.data.data);
          this.allTalents = response.data.data;
          this.dataLoading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.myImg {
  width: 55px;
  height: 55px;
  object-fit: contain;
  border-radius: 12px;
}
</style>
